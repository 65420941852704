// Variable overrides
button {
  //max-width: 200px;
  white-space: nowrap;
  //@media screen and (max-width: 575px) {
  //  max-width: 150px;
  //}
}

$primary: #065EE2;
$dark: #2E3539;
$btn-disabled-opacity: .35;