@import "Sidebar";
@import "Tabs";

// 버튼 hover 스타일 opacity: 0.75
.btn-primary:hover {
  background-color: rgb(5, 79, 189, .75);
  border-color: rgb(5, 79, 189, .1);
}

.btn-dark:hover {
  background-color: rgb(46, 53, 57, .75);
}

// 버튼 shadow 제거
// 기본
.c-app:not(.c-legacy-theme) .btn:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]) {
  box-shadow: none;
}
// hover
.c-app:not(.c-legacy-theme) .btn:hover:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]) {
  box-shadow: none;
}
// focus
.c-app:not(.c-legacy-theme) .btn:focus:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]), .c-app:not(.c-legacy-theme) .btn.focus:not([class*=ghost]):not([class*=link]):not([class*=outline]):not([class*=transparent]) {
  box-shadow: none;
}

// Card 테마 컬러 #f0f3f5
.card-header, .card-footer {
  background-color: #f0f3f5;
}

.table {
  white-space: nowrap;

  th {
    text-align: center;
    white-space: nowrap;
    border: 1px solid #c8ced3;
    background-color: #F4F4F4;
  }
  td {
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #c8ced3;
  }

  // 테이블 th 경계선 1px
  thead th {
    border-bottom: 1px solid;
    @include themes($table-theme-map, $create: parent) {
      border-bottom-color: themes-get-value("table-border-color");
    }
  }
}

//.card-header {
//  padding: $card-spacer-y 9.8px;
//}